import React, { useState } from "react";
import { graphql } from "gatsby";
import { Layout, SEO, DefaultHeader, Footer } from "components";
import styled from "styled-components";
import { P, H3, Content, Container } from "../mixins";

export const query = graphql`
  query PrivacyPageQuery {
    banner: file(relativePath: { eq: "assets/images/privacy-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default ({ data }) => {
  const banner = (data || {}).banner;
  return (
    <Layout>
      <SEO title="Privacy" description="LHS Privacy Policy" />
      <DefaultHeader
        title="Privacy Policy"
        description="View  our privacy policy below."
        imageFluid={banner}
      />
      <Container>
        <Content>
          <H3>GENERAL INFORMATION</H3>
          <P>
            Welcome to the LHS Privacy Policy page. When you use our web site services, you trust
            us with your information. This Privacy Policy is meant to help you understand what data
            we collect, why we collect it, and what we do with it. When you share information with
            us, we can make our services even better for you. For instance, we can show you more
            relevant search results. As you use our services, we want you to be clear how we`re
            using information and the ways in which you can protect your privacy. This is important;
            we hope you will take time to read it carefully. Remember, you can find controls to
            manage your information and protect your privacy and security. We’ve tried to keep it as
            simple as possible.
          </P>
          
          <H3>CHANGES TO THIS PRIVACY STATEMENT</H3>
          <P>
            Where a change is made to this Privacy Policy it will be set out on our websites to
            ensure that you are aware of what information we collect and how we will use it. If we
            plan to use Personal Data in a way that is inconsistent from that stated in this Privacy
            Policy, we will inform in advance by email, and it will be your decision as to whether
            or not you are happy that we continue store and process your information. This privacy
            policy is effective from 01 January, 2021.
          </P>
          <H3>ACCEPTANCE OF TERMS</H3>
          <P>
            Access to and use of this website is provided by LHS and subject to the following Terms
            of Use. Use of this site constitutes your acceptance of these terms which take effect on
            the date when you first use the site. LHS reserves the right to update the Terms of Use
            at any time without notice to you. Users are responsible for reviewing regularly
            information posted online to obtain timely notice of such changes. Your continued use of
            this site after changes are posted constitutes your acceptance of this agreement as
            modified by the posted changes.
          </P>
          <H3>RIGHT TO ACCESS, CORRECT AND DELETE DATA AND TO OBJECT TO DATA PROCESSING</H3>
          <P>
            Our customers have the right to access, correct and delete personal data relating to
            them, and to object to the processing of such data, by addressing a written request, at
            any time. The Company makes every effort to put in place suitable precautions to
            safeguard the security and privacy of personal data, and to prevent it from being
            altered, corrupted, destroyed or accessed by unauthorised third parties. However, the
            Company does not control each and every risk related to the use of the Internet, and
            therefore warns the Site users of the potential risks involved in the functioning and
            use of the Internet. The Site may include links to other web sites or other internet
            sources. As the Company cannot control these web sites and external sources, the Company
            cannot be held responsible for the provision or display of these web sites and external
            sources, and may not be held liable for the content, advertising, products, services or
            any other material available on or from these web sites or external sources.
          </P>
          <H3>SHARING YOUR INFORMATION</H3>
          <P>
            We share your personal data with your consent or as necessary to complete any
            transaction or provide any service you have requested or authorized. For example, we
            share your content with third parties when you tell us to do so. When you provide
            payment data to make a purchase, we will share payment data with banks and other
            entities that process payment transactions or provide other financial services, and for
            fraud prevention and credit risk reduction. 
          </P>
          <H3>MANAGEMENT OF PERSONAL DATA</H3>
          <P>
            You can view or edit your personal data online for many of our services. You can also
            make choices about our collection and use of your data. How you can access or control
            your personal data will depend on which services you use. You can choose whether you
            wish to receive promotional communications from our web site by email, SMS, physical
            mail, and telephone. If you receive promotional email or SMS messages from us and would
            like to opt out, you can do so by following the directions in that message. These
            choices do not apply to mandatory service communications that are part of certain web
            site services.
          </P>
          <H3>INFORMATION WE COLLECT</H3>
          <P>
            Our site collects data to operate effectively and provide you the best experiences with
            our services. You provide some of this data directly, such as when you create a personal
            account. We get some of it by recording how you interact with our services by, for
            example, using technologies like cookies, and receiving error reports or usage data from
            software running on your device. We also obtain data from third parties (including other
            companies).
          </P>
          <H3>HOW WE USE YOUR INFORMATION</H3>
          <P>
            Our web site uses the data we collect for three basic purposes: to operate our business
            and provide (including improving and personalizing) the services we offer, to send
            communications, including promotional communications, and to display advertising. In
            carrying out these purposes, we combine data we collect through the various web site
            services you use to give you a more seamless, consistent and personalized experience.
            However, to enhance privacy, we have built in technological and procedural safeguards
            designed to prevent certain data combinations. For example, we store data we collect
            from you when you are unauthenticated (not signed in) separately from any account
            information that directly identifies you, such as your name, email address or phone
            number.
          </P>
        </Content>
      </Container>
      <Footer />
    </Layout>
  );
};
